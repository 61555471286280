<template>
  <div class="galery flex row q-mt-lg">
    <div class="esquerdaMobi" v-if="isMobile">

      <q-scroll-area class="q-px-lg" style="width: 100%; height: calc(100vh - 15vh);">
        <q-expansion-item
          v-for="folder in folders"
          :key="folder"
          expand-separator
          :label="folder.path"
        >
          <q-btn
            flat
            no-caps
            v-for="f in folder.subFolders"
            :key="f"
            class="btnFolder q-my-sm"
            align="left"
            :label="f"
            @click="onFolders(folder.path, f)"
          />
        </q-expansion-item>
      </q-scroll-area>

    </div>
    <div class="esquerdaDesk" v-else>

      <q-scroll-area class="q-px-lg" style="width: 100%; height: calc(100vh - 15vh);">
        <q-expansion-item
          v-for="folder in folders"
          :key="folder"
          expand-separator
          icon="folder"
          :label="folder.path"
        >
          <q-btn
            flat
            no-caps
            v-for="f in folder.subFolders"
            :key="f"
            class="btnFolder q-my-sm"
            align="left"
            icon="folder"
            :label="f"
            @click="onFolders(folder.path, f)"
          />
        </q-expansion-item>
      </q-scroll-area>

    </div>
    <div class="direitaMobi" v-if="isMobile">
      <q-scroll-area class="q-px-lg" style="width: 100%; height: calc(100vh - 15vh);">
        <div class="flex row q-gutter-md" v-if="!isMobile">
          <div v-for="p in imgs" :key="p" class="cardDesk bg-verdeclaro">
            <q-img
              :src="p.path"
              spinner-color="primary"
              spinner-size="50px"
              class="img"
            />
            <div class="control">
              <q-btn flat class="btnControl" style="color: var(--primaryDark)" icon="far fa-eye" @click="onView(p.path)" />
              <q-btn flat class="btnControl" style="color: var(--primaryDark)" icon="fas fa-download" @click="onDownload(p.path)" />
            </div> 
          </div>
        </div>
        <div class="flex row q-gutter-md" v-else>
          <div v-for="p in imgs" :key="p" class="cardMobi bg-verdeclaro">
            <q-img
              :src="p.path"
              spinner-color="primary"
              spinner-size="50px"
              class="img"
            />
            <div class="control">
              <q-btn flat class="btnControl" style="color: var(--primaryDark)" icon="far fa-eye" @click="onView(p.path)" />
              <q-btn flat class="btnControl" style="color: var(--primaryDark)" icon="fas fa-download" @click="onDownload(p.path)" />
            </div> 
          </div>
        </div>
      </q-scroll-area>
    </div>
    <div class="direitaDesk" v-else>
      <q-scroll-area class="q-px-lg" style="width: 100%; height: calc(100vh - 15vh);">
        <div class="flex row q-gutter-md" v-if="!isMobile">
          <div v-for="p in imgs" :key="p" class="cardDesk bg-verdeclaro">
            <q-img
              :src="p.path"
              spinner-color="primary"
              spinner-size="50px"
              class="img"
            />
            <div class="control">
              <q-btn flat class="btnControl" style="color: var(--primaryDark)" icon="far fa-eye" @click="onView(p.path)" />
              <q-btn flat class="btnControl" style="color: var(--primaryDark)" icon="fas fa-download" @click="onDownload(p.path)" />
            </div> 
          </div>
        </div>
        <div class="flex row q-gutter-md" v-else>
          <div v-for="p in imgs" :key="p" class="cardMobi bg-verdeclaro">
            <q-img
              :src="p.path"
              spinner-color="primary"
              spinner-size="50px"
              class="img"
            />
            <div class="control">
              <q-btn flat class="btnControl" style="color: var(--primaryDark)" icon="far fa-eye" @click="onView(p.path)" />
              <q-btn flat class="btnControl" style="color: var(--primaryDark)" icon="fas fa-download" @click="onDownload(p.path)" />
            </div> 
          </div>
        </div>
      </q-scroll-area>
    </div>

    <!-- MODAL SUPER VIEW -->
    <q-dialog v-model="modalView" full-width>
      <div class="bg-white flex row justify-center">
        <div>
          <q-img
            :src="imgPath"
            spinner-color="primary"
            spinner-size="82px"
            class="q-mt-md"
            style="width: 80vw"
            v-if="isMobile"
          />
          <q-img
            :src="imgPath"
            spinner-color="primary"
            spinner-size="82px"
            class="q-mt-md"
            style="width: 35vw"
            v-else
          />
          <div class="q-my-md flex row justify-center">
            <q-btn outline color="negative" label="Fechar" @click="modalView = false" />
          </div>
        </div>
      </div>
    </q-dialog>
  </div>
</template>

<script>
import { defaultColor } from '../../helpers/defaultColor';
import { supabase } from '../../supabase';
import Axios from 'axios';

export default {
  data() {
    return {
      folders: [],
      imgs: [],
      modalView: false,
      imgPath: null,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    async getPath(folder) {
      const { data } = await supabase
        .storage
        .from('galeria')
        .getPublicUrl(folder)
      
      return data.publicURL;
    },
    async onFolders(folder, name) {

      const path = await this.getPath(folder)
      let address = folder + '/' + name

      const { data } = await supabase
        .storage
        .from('galeria')
        .list(address)

      this.imgs = data.map((img) => {
        return {
          path: path + '/' + name + '/' + img.name
        }
      })
    },
    onView(path){
      this.imgPath = path;
      this.modalView = true;
    },
    async onDownload(path){
      const ref = String(path).search('/galeria')
      const address = String(path).substring(ref + 9)
      const { data } = await supabase.storage.from('public/galeria').download(address)

      Axios.get(path, { responseType: 'blob' })
        .then(response => {
          const blob = data
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'gestaoEletrônicos'
          link.click()
          URL.revokeObjectURL(link.href)

          return response;
        }).catch(console.error)
    },
    async onFolderOne() {
      let folder = [];
      const { data } = await supabase
        .storage
        .from('galeria')
        .list()

      folder = data.map((folder) => {
        return {
          name: folder.name
        }
      });

      return folder; 
    },
    async onFolder(folder) {
      let folders = [];
      const { data } = await supabase
        .storage
        .from('galeria')
        .list(folder)

      folders = data.map((f) => {
        return {
          path: f.name
        }
      })

      // console.log(this.folder);
      return folders;
    },
  },
  async created() {
    defaultColor();

    let primaryFolder = await this.onFolderOne()
    primaryFolder.forEach(async folder => {
      let folderOpen = await this.onFolder(folder.name);
      let subFolders = [];

      subFolders = folderOpen.map((sub) => {
        return sub.path
      })

      this.folders.push({
        path: folder.name,
        subFolders
      })
    });
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');

  .esquerdaDesk {
    width: 30%;
    height: calc(100vh - 15vh);

    border-right: grey solid 3px;
  }
  .esquerdaMobi {
    width: 45%;
    height: calc(100vh - 15vh);

    border-right: grey solid 3px;
  }
  .direitaDesk {
    width: 70%;
    height: calc(100vh - 15vh);
  }
  .direitaMobi {
    width: 55%;
    height: calc(100vh - 15vh);
  }
  .btnFolder {
    color: var(--primaryDark);
    width: 90%;
  }
  .cardDesk {
    width: 25%;
  }
  .cardMobi {
    width: 85%;
  }
  .img {
    border: var(--primaryDark) solid 10px;
  }
  .btnControl {
    width: 50%;
  }

</style>